import React, { Component } from 'react';
import { Form, Input, Popover, Tooltip, Icon, Alert } from 'antd-min';
import { GLGlobal, FormHelper, ComparisonOperator } from 'gl-commonui';
import { fmtMsg, GSSchoolAction } from '@app/util';
import { GSAdminLocale } from '@app/locales/localeid';
import { FormattedMessage } from 'react-intl';

export const SchoolNotes = ({ schoolId, notes, form, updateNotes }) => {
    if (!notes || notes.trim() === "") {
        return null;
    }
    return (GLGlobal.isActionValid(GSSchoolAction.EditSchoolInternalNotes) &&
        <div className="school-notes">
            <Alert
                message={
                    <>
                        <b>
                        <FormattedMessage id={GSAdminLocale.SchoolModeNotes} />
                        <Tooltip
                            title={<FormattedMessage id={GSAdminLocale.SchoolModeNotesPlaceHolder} />}
                            overlayClassName="school-notes-popover"
                            placement="topLeft"
                            //getPopupContainer={(node) => node.parentElement}
                            key="school-notes"
                        >
                            <Icon type="info-circle" className="contact-info-icon" />
                        </Tooltip >
                        <span> : </span>
                        </b>
                        <span className="school-notes-value">
                            &nbsp;{notes.trim()}
                        </span>
                    </>
                }
                className="school-notes__msg"
                type="success"
            >
            </Alert>
        </div>
    )
}