import React, {useState} from 'react';
import {Link} from "react-router-dom";
import {FormattedMessage} from 'react-intl';
import {Badge, Dropdown, Icon, Menu} from 'antd-min';
import {alignPop, GLGlobal, GLUtil} from 'gl-commonui';
import {InvitationTemplateIcon} from '@app/components/svgicon';
import {ClassHelper, fmtMsg, GSAdminAction, GSSchoolAction, IconFont, subscriptionTypeUsage} from '@app/util';
import {GSAdminLocale, SchoolLocale} from '@app/locales/localeid';
import {GLIcon} from "@app/components";
import {PathConfig} from "@app/config/pathconfig";

export const SchoolFeatureMenu = ({usage, userRoles, allowMaterialRequest, allowSchoolEditLicense, requestCount, pathVisitation, regionId, schoolId, campuses}) => {
    const createPath = (pathConfig: string) => {
        return regionId && schoolId ? GLUtil.pathStringify(pathConfig, { regionId, schoolId }) : null;
    }
    const pathCampusNew = createPath(PathConfig.CampusNew);
    const pathInvitation = createPath(PathConfig.Invitations);
    const pathTeacher = createPath(PathConfig.SchoolTeachers);
    const pathLicenses = createPath(PathConfig.SchoolLicenses);
    const pathChanges = createPath(PathConfig.SchoolChangeHistory);
    const pathEdit = createPath(PathConfig.Settings);
    const pathAdmin = createPath(PathConfig.SchoolAdmin)
    const pathAccountManagers = createPath(PathConfig.AccountManagers);
    const pathTrainers = createPath(PathConfig.SchoolTrainers);
    const hasFirstDivider = pathEdit && GLGlobal.isActionValid(GSSchoolAction.EditSchool);
    const hasSecondDivider = (pathAdmin || pathAccountManagers || pathTrainers) && (GLGlobal.isActionValid(GSSchoolAction.EditSchoolAdmins) || GLGlobal.isActionValid(GSSchoolAction.EditSchoolAccountManager) || GLGlobal.isActionValid(GSAdminAction.EditSchoolTrainer));
    const hasThirdDivider = (hasFirstDivider || hasSecondDivider) && pathCampusNew && GLGlobal.isActionValid(GSSchoolAction.AddCampus);
    const [menuVisible, setMenuVisible] = useState(false);
    let hasShoppingCart = false;
    let pathShoppingCart = '';
    if (campuses && campuses.length) {
        const campus = campuses.find(c => !c.disabled);
        if (campus) {
            const params = {regionId: regionId, schoolId, campusId: campus.id};
            pathShoppingCart = params.regionId && params.schoolId && params.campusId ? GLUtil.pathStringify(PathConfig.SchoolCart, params) : null;
            hasShoppingCart = pathShoppingCart && allowMaterialRequest && GLGlobal.isActionValid(GSSchoolAction.CampusCart);
        }
    }

    const menus = (
        <Menu className="school-feature-menu">
            {pathEdit && GLGlobal.isActionValid(GSSchoolAction.EditSchool) &&
                <Menu.Item>
                    <Link to={pathEdit}>
                        <Icon type="edit" />
                        <FormattedMessage id={SchoolLocale.EditSchool} />
                    </Link>
                </Menu.Item>}
                {hasFirstDivider && <Menu.Divider />}
                {pathAdmin && GLGlobal.isActionValid(GSSchoolAction.EditSchoolAdmins) &&
                <Menu.Item>
                    <Link to={pathAdmin}>
                        <IconFont type="glscoach" />
                        <FormattedMessage id={SchoolLocale.AdminsAdmin} />
                    </Link>
                </Menu.Item>}
                {pathAccountManagers && GLGlobal.isActionValid(GSSchoolAction.EditSchoolAccountManager) &&
                <Menu.Item>
                    <Link to={pathAccountManagers}>
                        <Icon type="team" />
                        <FormattedMessage id={SchoolLocale.AdminsAccountManager} />
                    </Link>
                </Menu.Item>}
            {pathTrainers && GLGlobal.isActionValid(GSAdminAction.EditSchoolTrainer) &&
                <Menu.Item>
                    <Link to={pathTrainers}>
                        <Icon type="team" />
                        <FormattedMessage id={GSAdminLocale.SchoolCardTitleTrainer} />
                    </Link>
                </Menu.Item>}
                {hasSecondDivider && <Menu.Divider />}
            {pathCampusNew && GLGlobal.isActionValid(GSSchoolAction.AddCampus) &&
                <Menu.Item>
                    <Link to={pathCampusNew}>
                        <Icon type="plus-circle-o" />
                        <FormattedMessage id={SchoolLocale.CampusAdd} />
                    </Link>
                </Menu.Item>}
            {hasThirdDivider && <Menu.Divider />}
            {pathInvitation && GLGlobal.isActionValid(GSSchoolAction.ListSchoolTeacher) &&
                <Menu.Item>
                    <Link to={pathTeacher}>
                        <Icon type="team" />
                        <FormattedMessage id={SchoolLocale.SchoolTeacherTitle} />
                    </Link>
                </Menu.Item>}
            {hasShoppingCart &&
            <Menu.Item>
                <Link to={pathShoppingCart}>
                    <GLIcon type="shopping-cart" />
                    <FormattedMessage id={SchoolLocale.CampusIconCart} />
                </Link>
            </Menu.Item>}
            {pathInvitation &&
                <Menu.Item>
                    <Link to={pathInvitation}>
                        <InvitationTemplateIcon />
                        <FormattedMessage id={SchoolLocale.InvitationPortalInvitations} />
                    </Link>
                </Menu.Item>}
            <Menu.Divider />
            {pathChanges && GLGlobal.isActionValid(GSAdminAction.ListSchoolVisitationHistory) &&
                <Menu.Item>
                <Link to={pathVisitation}>
                    <Icon type="switcher" />
                    <FormattedMessage id={GSAdminLocale.SchoolVisitationTitle} />
                </Link>
            </Menu.Item>}
            {pathLicenses && GLGlobal.isActionValid(GSAdminAction.ListSchoolBillingHistory)  &&
                <Menu.Item>
                    <Link to={pathLicenses}>
                        <Icon type="switcher" />
                        <FormattedMessage id={GSAdminLocale.SchoolLicenseTitle} />
                    </Link>
                </Menu.Item>}
            {pathChanges && GLGlobal.isActionValid(GSAdminAction.ListSchoolChangeHistory)  &&
                <Menu.Item>
                    <Link to={pathChanges}>
                        <Icon type="switcher" />
                        <FormattedMessage id={SchoolLocale.CIMSSchoolLogTitle} />
                    </Link>
                </Menu.Item>}
        </Menu>
    );
    return (
        <div className='school-feature-menu-icon'>
            <Dropdown overlay={menus} trigger={['click']} onVisibleChange={setMenuVisible} {...alignPop()}>
                <span> <Icon type="unordered-list" /> {fmtMsg({ id: GSAdminLocale.SchoolManageSchool })} {menuVisible ? <Icon type="up"/> : <Icon type="down"/>}</span>
            </Dropdown>
        </div>
    )
}
