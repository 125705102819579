import {Container, GLIcon, ListStatesRadios, ListStatesStore, MainTitle} from '@app/components';
import {GLGridSorter} from '@app/components/gl-grid';
import {PathConfig} from '@app/config/pathconfig';
import {GSAdminLocale, SchoolLocale} from "@app/locales/localeid";
import {CampusModel} from '@app/service/school/campus/index';
import {StateType} from '@app/states';
import {approveBySchool} from '@app/states/cims';
import {getSchoolContacts} from "@app/states/school/admins";
import {SchoolContacts} from '@app/states/school/adminsModel';
import {getAccountManager, getAdmin, getItemsBy, getPrimary, getTrainer} from '@app/states/school/campus';
import {getSchoolsWhenSelect, setSchoolUserPermission, updateSchoolNotes} from '@app/states/school/school';
import { fetchTagListAction } from "@app/states/tag-manager/tag-manager";
import {getSubscriptionRequestCount} from '@app/states/school/student-subscription-request';
import {
    ClassHelper,
    ContextHelper,
    fmtMsg,
    getQuery,
    GSAdminAction,
    GSSchoolAction,
    lazyInject,
    setQueryState,
    TYPES
} from '@app/util';
import {TableProps} from 'antd/lib/table';
import {
    connect,
    GLAction,
    GLForm,
    GLFormComponentProps,
    GLGlobal,
    GLLocale,
    GLUtil,
    InvitationType,
    PaginationParams,
    Role,
    withRouter
} from 'gl-commonui';
import * as React from 'react';
import {Component} from 'react';
import {FormattedMessage} from "react-intl";
import {Link, RouteComponentProps} from "react-router-dom";
import {CampusTable} from './component';
import {SchoolAdmins} from './component/school-admins';
import {SchoolFeatureMenu} from './component/school-feature-menu';
import {SchoolNotes} from './component/school-notes';
import {SchoolPendingChange} from './component/school-pending-change';
import {ISchoolService} from "@app/service/schools";
import { Tagging } from '@app/components/tagging/tagging';
import { EntityTagsModel, TagEntity, TagManagerService } from '@app/service/tag-manager';
import "./campus.less";

interface CampusesPageProps {
    name: string;
    schools: any[];
    model: any;
    accountManager: string;
    trainer: string;
    list: any;
    total: number;
    activeCnt: number;
    loading: boolean;
    roleChanged;
    requestCount: number;
    allowSchoolEditLicense: boolean;
    userRoles: string[];
    subscriptionTypeUsage: any;
    schoolId?: string;
    regionId?: string;
    notes?: string;
    pendingChangesCount?: number
    schoolContacts?: SchoolContacts;
    getItemsBy: (id) => void;
    getAccountManager: (d) => void;
    getAdmin: (d) => void;
    getTrainer: (d) => void;
    getPrimary: (d) => void;
    getSchoolsWhenSelect: () => void;
    setSchoolUserPermission: (d) => void
    getSubscriptionRequestCount: (d) => void;
    getSchoolContacts: (id: string) => void;
    updateSchoolNotes: (d) => void;
    approveBySchool: (d) => void;
    fetchTagListAction: (d) => void;
}
// class CampusesTable extends Table<CampusModel> { }

@withRouter
@connect(({ campus: { list, loading, total, activeCnt, accountManager, trainer, model },
    school: { current: { name, allowSchoolEditLicense, subscriptionTypeUsage, id: schoolId, regionId, notes, pendingChangesCount },
        list: schools, roleChanged, userRoles
    },
    admins: { schoolContacts },
    tagManager: { tagList },
    studentSubscription: { requestCount } }: StateType) => ({
        name,
        schools,
        list,
        loading,
        total,
        activeCnt,
        accountManager,
        trainer,
        model,
        roleChanged,
        requestCount,
        userRoles,
        allowSchoolEditLicense,
        subscriptionTypeUsage,
        schoolId,
        regionId,
        notes,
        pendingChangesCount,
        schoolContacts
    }), {
    getItemsBy,
    getAccountManager,
    getAdmin,
    getTrainer,
    getPrimary,
    getSchoolsWhenSelect,
    setSchoolUserPermission,
    getSubscriptionRequestCount,
    getSchoolContacts,
    updateSchoolNotes,
    approveBySchool,
    fetchTagListAction,
})
@GLForm.create()
export class CampusesPage extends Component<RouteComponentProps<any> & CampusesPageProps & GLFormComponentProps, { listState, allowMaterialRequest }> {
    tableLayout: TableProps<CampusModel> = {
        rowKey: 'id',
        bordered: false,
        loading: true,
        pagination: new PaginationParams(1, 10),
        onChange: (pagination: any) => {
            this.onPagingChange(pagination);
        },
        rowClassName: (record) => record.disabled ? "list-disabled" : "",
        onRow: ({ id }) => ({
            onClick: () => setQueryState('classes', new PaginationParams(1, 10), { campusId: id })
        })
    };
    Campuss: CampusModel[];
    loading: boolean = true;
    listStateStore: ListStatesStore

    @lazyInject(TYPES.ISchoolService)
    schoolService: ISchoolService

    @lazyInject(TYPES.ITagManagerService)
    tagManagerService: TagManagerService

    private sorter: {sortBy: string | string[], isDescending: boolean | boolean[]}
    constructor(props, context) {
        super(props, context)
        this.listStateStore = new ListStatesStore("campuses-list-states", this.props.match.params.schoolId);
        this.state = {
            listState: this.listStateStore.getState(),
            allowMaterialRequest: false
        }
        this.sorter = {
            sortBy: 'name',
            isDescending: false
        };
    }

    getSchool = async () => {
        const data = await this.schoolService.getSchool({id: this.props.match.params.schoolId});
        if (data) {
            this.setState({
                allowMaterialRequest: data.allowMaterialRequest
            });
        }
    }

    async componentDidMount() {
        this.props.getAccountManager({ resourceType: Role.AccountManager, resourceId: this.props.match.params.schoolId });
        this.props.getAdmin({ resourceType: InvitationType.schoolAdmin, resourceId: this.props.match.params.schoolId });
        this.props.getSchoolContacts(this.props.match.params.schoolId);
        this.props.getTrainer({ resourceType: InvitationType.trainer, resourceId: this.props.match.params.schoolId });
        const page = this.tableLayout.pagination as PaginationParams;
        page.current = getQuery('campuses').current;
        this.props.getPrimary({ id: this.props.match.params.schoolId })
        this.props.getItemsBy({ schoolId: this.props.match.params.schoolId, query: this.listStateStore.getUrlQuery(page.toRequest()), sorter: this.sorter })
        this.props.getSchoolsWhenSelect();
        !this.props.userRoles && this.props.setSchoolUserPermission(this.props.match.params.schoolId);
        if (this.props.userRoles
            && ClassHelper.canEditLicense(this.props.userRoles, this.props.allowSchoolEditLicense)
            && GLGlobal.isActionValid(GSSchoolAction.EditLicense)) {
            this.props.getSubscriptionRequestCount({ query: { schoolId: this.props.match.params.schoolId, userRoles: ContextHelper.getUserLoginInfo().profile.roles } });
        }
        await this.getSchool();
        this.props.fetchTagListAction({ regionId: this.props.regionId, entity: TagEntity.Campus })
    }
    componentWillReceiveProps(nextProps) {

        if (nextProps.match.params.schoolId != this.props.match.params.schoolId) {
            const page = this.tableLayout.pagination as PaginationParams;
            page.current = getQuery('campuses').current;
            this.props.getItemsBy({ schoolId: nextProps.match.params.schoolId, query: this.listStateStore.getUrlQuery(page.toRequest()), sorter: this.sorter })
            this.listStateStore.setStateKey(nextProps.match.params.schoolId);
        }

        if (this.props.userRoles != nextProps.userRoles || this.props.allowSchoolEditLicense != nextProps.allowSchoolEditLicense || nextProps.match.params.schoolId != this.props.match.params.schoolId) {
            if (ClassHelper.canEditLicense(nextProps.userRoles, nextProps.allowSchoolEditLicense && GLGlobal.isActionValid(GSSchoolAction.EditLicense))) {
                this.props.getSubscriptionRequestCount({ query: { schoolId: nextProps.match.params.schoolId, userRoles: ContextHelper.getUserLoginInfo().profile.roles } });
            }
        }
    }

    getColumnSorter(sorter: GLGridSorter) {
        if (!sorter) return null;
        if (sorter.columnName == "address") {
            return { sortBy: ['address1', 'address2'], isDescending: [!sorter.ascending, !sorter.ascending] };
        }
        else {
            return { sortBy: sorter.columnSortName, isDescending: !sorter.ascending };
        }
    }

    onColumnSorting(sorter: GLGridSorter) {
        this.sorter = this.getColumnSorter(sorter);
        const page = this.tableLayout.pagination as PaginationParams;
        this.props.getItemsBy({
            schoolId: this.props.match.params.schoolId,
            query: this.listStateStore.getUrlQuery(page.toRequest()),
            sorter: this.sorter
        })
    }

    onPagingChange(pagination) {
        const page = this.tableLayout.pagination as PaginationParams;
        page.current = pagination.current ? pagination.current : pagination;
        this.props.getItemsBy({
            schoolId: this.props.match.params.schoolId,
            query: this.listStateStore.getUrlQuery(page.toRequest()),
            sorter: this.sorter
        })
    }
    getColumns() {
        const { regionId, schoolId } = this.props;
        return [
            {
                title: fmtMsg({id: SchoolLocale.CampusName}),
                dataIndex: "name",
                width: "14%",
                className:'navigation-col',
                render: (text, record, index) => {
                    const pathClasses = regionId && schoolId ? GLUtil.pathStringify(PathConfig.Classes, { regionId, schoolId, campusId: record.id }) : null;
                    if (pathClasses) {
                        return <a href={`${pathClasses}`} > <span>{text}</span> </a>;
                    }
                    else {
                        return <span>{text}</span>;
                    }
                }
            }, {
                title: fmtMsg({id: SchoolLocale.CampusGSNumber}),
                dataIndex: "gsNumber",
                width: "10%"
            },{
                title: fmtMsg({id: SchoolLocale.CampusAddress}),
                dataIndex: "fullAddress",
                width: "30%"
            }, {
                title: fmtMsg({id: SchoolLocale.CampusPhone}),
                dataIndex: "phone",
                width: "13%"
            }, {
                title: fmtMsg({id: SchoolLocale.CampusFax}),
                dataIndex: "fax",
                width: "13%"
            },
            {
                title: fmtMsg({id: GSAdminLocale.SchoolModelTags}),
                dataIndex: "tags",
                width: "25%"
            },
        ];
    }

    renderContent() {
        const {list, loading, total, activeCnt, accountManager, trainer, model, userRoles, allowSchoolEditLicense,
            subscriptionTypeUsage: usage, regionId, schoolId, notes, pendingChangesCount,
            schoolContacts: contacts, form, updateSchoolNotes, approveBySchool} = this.props;
        const pathSchoolEdit = regionId && schoolId ? GLUtil.pathStringify(PathConfig.Settings, { regionId, schoolId }) : null;
        const pathAccountManager = regionId && schoolId ? GLUtil.pathStringify(PathConfig.AccountManagers, { regionId, schoolId }) : null;
        const pathAdmin = regionId && schoolId ? GLUtil.pathStringify(PathConfig.Admins, { regionId, schoolId }) : null;
        const pathTrainer = regionId && schoolId ? GLUtil.pathStringify(PathConfig.SchoolTrainers, { regionId, schoolId }) : null;
        const pathVisitation = regionId && schoolId ? GLUtil.pathStringify(PathConfig.SchoolVisitationHistory, { regionId, schoolId }) : null;
        this.tableLayout.columns = this.getColumns();
        this.tableLayout.dataSource = list;
        this.tableLayout.loading = loading;
        (this.tableLayout.pagination as PaginationParams).total = ListStatesStore.getStateCnt(total, activeCnt, this.state);
        this.tableLayout.pagination['hideOnSinglePage'] = true;
        const hasTagViewPermission = GLGlobal.isActionValid(GSAdminAction.SchoolTagsView);
        const hasTagManagerPermission = GLGlobal.isActionValid(GSAdminAction.SchoolTagsManage);
        return (
            <div className="page-content">
                <div className='school'>
                    <div>
                        <div className='school-select'>
                            <div><span><FormattedMessage id={SchoolLocale.CampusSchoolSelect} /> : </span></div>
                            <div className="schoolname-row">
                                <span className="schoolname-row__school">
                                    {this.props.name}
                                    {pathSchoolEdit && (
                                        <GLAction action={GSSchoolAction.SchoolSetting}>
                                            <Link to={pathSchoolEdit}>
                                                <GLIcon type='edit' labelId={SchoolLocale.SettingsTitle} />
                                            </Link>
                                        </GLAction>
                                    )}
                                </span>
                                <div className="schoolname-row__tags">
                                    {hasTagViewPermission && regionId && (
                                        <Tagging
                                            entityId={schoolId}
                                            regionId={regionId}
                                            entity={TagEntity.School}
                                            isViewOnly={!hasTagManagerPermission}
                                            popoverPlacement="bottomLeft"
                                        />
                                    )}
                                </div>                                
                                <div className="schoolname-row__menu">
                                    <SchoolFeatureMenu {...{
                                        usage,
                                        userRoles,
                                        allowMaterialRequest: this.state.allowMaterialRequest,
                                        allowSchoolEditLicense,
                                        requestCount: this.props.requestCount,
                                        pathVisitation,
                                        regionId,
                                        schoolId,
                                        campuses: list
                                    }} />
                                </div>
                            </div>
                        </div>
                        <SchoolNotes {...{schoolId, notes, form, updateNotes: updateSchoolNotes}} />
                        <SchoolPendingChange {...{schoolId, pendingChangesCount, pathChanges: regionId && schoolId && GLUtil.pathStringify(PathConfig.SchoolChangeHistory, { regionId, schoolId }), approveBySchool}} />
                        <SchoolAdmins {...{model, accountManager, contacts, trainer, pathAccountManager, pathAdmin, pathTrainer}}/>
                    </div>
                </div>
                <div className="school-list-states">
                    <div><span><FormattedMessage id={SchoolLocale.CampusTitle}/> : </span></div>
                    <ListStatesRadios allCnt={total}
                                      activeCnt={activeCnt}
                                      value={this.state.listState}
                                      onChange={(state) => {
                                          this.setState({listState: state});
                                          this.listStateStore.setState(state);
                                          (this.tableLayout.pagination as PaginationParams).current = 1;
                                          this.onPagingChange(new PaginationParams(1, 30));
                                      }}/>
                </div>
                <CampusTable
                    tableProps={{...this.tableLayout}}
                    routeMatch={this.props.match}
                />
            </div>
        );
    }

    render() {
        return <Container fullWidth className='campuses-page'>
            <MainTitle plain={this.props.name} />
            {this.renderContent()}
        </Container>
    }
}
