import React, { Component } from 'react';
import { Modal } from "antd-min";
import { GLGlobal, GLLocale } from 'gl-commonui';
import { fmtMsg, GSSchoolAction } from '@app/util';
import { GSAdminLocale, SchoolLocale } from '@app/locales/localeid';

export const SchoolPendingChange = ({ schoolId, pendingChangesCount, pathChanges, approveBySchool }) => {
    const promptMessage = fmtMsg({ id: GSAdminLocale.SchoolPendingChangesPromptMessage }, { pendingChangesCount, linkApprove: "{linkApprove}", linkView: "{linkView}" });
    const promptMessage2View = promptMessage && promptMessage.split("{linkView}");
    const promptMessage2Approve = promptMessage2View && promptMessage2View[2].split("{linkApprove}");
    const promptMessage4Coach = fmtMsg({ id: GSAdminLocale.SchoolPendingChangesPromptMessage4Coach }, { pendingChangesCount, linkView: "{linkView}" });
    const promptMessage2View4Coach = promptMessage4Coach && promptMessage4Coach.split("{linkView}");
    if (GLGlobal.isActionValid(GSSchoolAction.AcknowledgeSchool) && pendingChangesCount > 0) {
        return (
            <div className="school-pending-change">
                <span className="ant-form-text">
                    {promptMessage2View[0]}
                    <a href={pathChanges} >{promptMessage2View[1]}</a>
                    {promptMessage2Approve[0]}
                    <a onClick={() => {
                        const fmtMsg = GLGlobal.intl.formatMessage;
                        Modal.confirm({
                            title: fmtMsg({ id: SchoolLocale.CIMSApproveSchoolChangesPromptMessage }),
                            okText: fmtMsg({ id: GLLocale.Ok }),
                            cancelText: fmtMsg({ id: GLLocale.Cancel }),
                            onOk: () => { approveBySchool({ schoolId }) },
                        })
                    }} >{promptMessage2Approve[1]}</a>
                    {promptMessage2Approve[2]}
                </span>
            </div>            
        )
    }
    else if (GLGlobal.isActionValid(GSSchoolAction.AcknowledgeClassUnitPlan) && pendingChangesCount > 0) {
        return (
            <div className="school-pending-change">
                <span className="ant-form-text">
                    {promptMessage2View4Coach[0]}
                    <a href={pathChanges} >{promptMessage2View4Coach[1]}</a>
                    {promptMessage2View4Coach[2]}
                </span>
            </div>
        )
    }
    else {
        return null;
    }
}