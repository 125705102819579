import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { FormattedMessage } from 'react-intl';
import { Icon, Popover, Table } from 'antd-min';
import { GLAction, GLGlobal } from 'gl-commonui';
import { GLIcon } from '@app/components';
import { fmtMsg, GSAdminAction, GSSchoolAction, SchoolAdminContacts } from '@app/util';
import { SchoolLocale, GSAdminLocale } from '@app/locales/localeid';

export const SchoolAdmins = ({model, accountManager, contacts, trainer, pathAccountManager, pathAdmin, pathTrainer}) => {

    const getContactsColumns = () => {
        const noneMessage = fmtMsg({ id: SchoolLocale.TextNone });
        return [
            {
                title: fmtMsg({ id: SchoolLocale.AdminPopoverType }),
                dataIndex: "type",
                key: "type",
                width: 100,
                render(text, recorder) {
                    if (text == SchoolAdminContacts.MainShippingContact) {
                        return fmtMsg({ id: SchoolLocale.AdminPopoverShipping });
                    } else if (text == SchoolAdminContacts.MainSupportContact) {
                        return fmtMsg({ id: SchoolLocale.AdminPopoverSupport });
                    } else if (text == SchoolAdminContacts.MainBillingContact) {
                        return fmtMsg({ id: SchoolLocale.AdminPopoverBilling });
                    }
                }
            },
            {
                title: fmtMsg({ id: SchoolLocale.AdminPopoverEmail }),
                dataIndex: "email",
                key: "email",
                width: 250,
                render(text, recorder) {
                    return text ? <div><a href={`mailto:${text}`} title={text}>{text}</a></div> : noneMessage;
                }
            },
            {
                title: fmtMsg({ id: SchoolLocale.AdminPopoverPhone }),
                dataIndex: "phone",
                key: "phone",
                width: 170,
                render(text, recorder) {
                    return text ? <div><a href={`tel:${text}`} title={text}>{text}</a></div> : noneMessage;
                }
            }
        ];
    }

    const renderContactContent = () => {
        const formattedContacts = [];
        Object.entries(contacts).forEach(item => {
            if (item[0] == SchoolAdminContacts.MainShippingContact ||
                item[0] == SchoolAdminContacts.MainSupportContact ||
                item[0] == SchoolAdminContacts.MainBillingContact) {
                formattedContacts.push({ type: item[0], ...item[1] });
            }
        });
        return <Table
            pagination={false}
            dataSource={formattedContacts}
            columns={getContactsColumns()}
            scroll={{ x: 520 }}
        />
    }

    return (
        <div className='school-admins'>
            <GLAction action={GSSchoolAction.ListSchoolAccountManager}>
                <div className='account-manager'>
                    <div><FormattedMessage id={SchoolLocale.AdminsAccountManager} /> :</div>
                    {model.admin != undefined &&
                        <div>
                            <span className='name'>{accountManager ? accountManager : GLGlobal.intl.formatMessage({ id: SchoolLocale.TextNone })}</span>
                            {pathAccountManager &&
                                <GLAction action={GSSchoolAction.EditSchoolAccountManager}>
                                    <Link to={pathAccountManager}>
                                        <GLIcon type='edit' labelId={SchoolLocale.CampusIconAdmins} />
                                    </Link>
                                </GLAction>}
                        </div>
                    }
                </div>
            </GLAction>
            <div className='school-admin'>
                <div><FormattedMessage id={SchoolLocale.AdminsAdmin} /> :</div>
                {model.admin != undefined &&
                    <div>
                        <span className='name'>{model.admin ? model.admin : GLGlobal.intl.formatMessage({ id: SchoolLocale.TextNone })}</span>

                        {pathAdmin &&
                            <GLAction action={GSSchoolAction.EditSchoolAdmins}>
                                <Link to={pathAdmin}>
                                    <GLIcon type='edit' labelId={SchoolLocale.CampusIconAdmins} />
                                </Link>
                            </GLAction>}
                        {contacts && (contacts[SchoolAdminContacts.MainBillingContact] ||
                            contacts[SchoolAdminContacts.MainShippingContact] ||
                            contacts[SchoolAdminContacts.MainSupportContact])
                            ? <Popover
                                title={fmtMsg({ id: SchoolLocale.AdminPopoverSchoolContacts })}
                                content={renderContactContent()}
                                overlayClassName="contact-popover"
                                placement="bottom"
                                getPopupContainer={(node) => node.parentElement}
                                key="admin-contacts"
                            >
                                <Icon type="info-circle" className="contact-info-icon" />
                            </Popover>
                            : null
                        }
                    </div>
                }
            </div>
            <GLAction action={GSAdminAction.ListSchoolTrainer}>
                <div className='coach'>
                    <div><FormattedMessage id={GSAdminLocale.SchoolCardTitleTrainer} /> :</div>
                    {trainer != undefined &&
                        <div>
                            <span className='name'>{trainer ? trainer : GLGlobal.intl.formatMessage({ id: SchoolLocale.TextNone })}</span>
                            {pathTrainer &&
                                <GLAction action={GSAdminAction.EditSchoolTrainer}>
                                    <Link to={pathTrainer}>
                                        <GLIcon type='edit' labelId={SchoolLocale.CampusIconAdmins} />
                                    </Link>
                                </GLAction>}
                        </div>
                    }
                </div>
            </GLAction>
        </div>
    )
}